import React from "react"
import { SvgGraphic } from "../components/image"

export const MovementLogo: SvgGraphic = props => (
  <SvgGraphic viewBox="0 0 152 152" {...props}>
    <title></title>
    <path fill="currentColor" d="M76,0a76,76,0,1,0,76,76A76,76,0,0,0,76,0Zm0,150a74,74,0,1,1,74-74A74.08,74.08,0,0,1,76,150Z" />
    <path fill="currentColor" d="M76,51.94a24,24,0,1,0-24-24A24,24,0,0,0,76,51.94Zm0-46a22,22,0,1,1-22,22A22,22,0,0,1,76,5.93Z" />
    <path fill="currentColor" d="M76,52a24,24,0,1,0,24,24A24,24,0,0,0,76,52Z" />
    <path fill="currentColor" d="M22.37,72.76A24,24,0,1,0,13.59,40,24,24,0,0,0,22.37,72.76Z" />
    <polygon fill="currentColor" points="34.35 76.03 34.35 76.03 34.35 76.03 34.35 76.03" />
    <path fill="currentColor" d="M55.16,88A24,24,0,0,0,34.35,76v2h0a22,22,0,1,1-19,33,22,22,0,0,1,19-33V76h0A24,24,0,1,0,55.16,88Z" />
    <path fill="currentColor" d="M76,100.06a24,24,0,1,0,24,24A24,24,0,0,0,76,100.06Z" />
    <path fill="currentColor" d="M129.63,79.24A24,24,0,1,0,138.41,112,23.91,23.91,0,0,0,129.63,79.24ZM136.68,111a22,22,0,1,1-8.05-30A22.09,22.09,0,0,1,136.68,111Z" />
    <path fill="currentColor" d="M96.84,64a24,24,0,1,0,8.78-32.79A24,24,0,0,0,96.84,64Z" />
  </SvgGraphic>
)

export const BlueprintLogo: SvgGraphic = props => (
  <SvgGraphic viewBox="0 0 152 152" {...props}>
    <title></title>
    <path fill="currentColor" d="M148,100.28a76,76,0,0,0-27.7-86l-.46-.33h0a76,76,0,0,0-87.72,0h0l-.44.32A76,76,0,0,0,4,100.32l0,.12H4A75.94,75.94,0,0,0,75,152v0h2v0a75.94,75.94,0,0,0,71-51.53h0ZM150,76a74.26,74.26,0,0,1-3.29,21.92l-14.64-4.75a58.75,58.75,0,0,0-20.8-64l9-12.45a75.16,75.16,0,0,1,8,7A73.53,73.53,0,0,1,150,76ZM22.48,94.44l15.36-5,1.92-.62c-.22-.63-.43-1.26-.62-1.91A38.37,38.37,0,0,1,52.61,45.51L61.1,57.19A24,24,0,0,0,75,100V132.6A56.71,56.71,0,0,1,22.48,94.44Zm54.52,20V100A24,24,0,0,0,98.5,84.36l13.74,4.47A38.52,38.52,0,0,1,77,114.43ZM76,52a24,24,0,0,0-13.29,4L54.23,44.33a38.34,38.34,0,0,1,43.54,0L89.29,56A24,24,0,0,0,76,52Zm0-16.45a40.28,40.28,0,0,0-23,7.14l-9.49-13a56.45,56.45,0,0,1,64.9,0L99,42.69A40.28,40.28,0,0,0,76,35.55Zm38.77,52-1.91-.63L99.12,82.46A24,24,0,0,0,90.9,57.19l8.49-11.68,1.19-1.64,9.49-13.06a56.57,56.57,0,0,1,20.08,61.73ZM23.66,23.66a74.19,74.19,0,0,1,8-6.95l9,12.45a58.75,58.75,0,0,0-20.8,64L5.29,97.92A74.23,74.23,0,0,1,23.66,23.66ZM128.34,128.34A73.52,73.52,0,0,1,77,150V134.6a58.59,58.59,0,0,0,54.44-39.53l14.65,4.75A73.75,73.75,0,0,1,128.34,128.34Z" />
  </SvgGraphic>
)

export const WireLogo: SvgGraphic = props => (
  <SvgGraphic viewBox="0 0 152 152" {...props}>
    <title></title>
    <path fill="currentColor" d="M76,2A74,74,0,1,1,2,76,74.09,74.09,0,0,1,76,2m0-2a76,76,0,1,0,76,76A76,76,0,0,0,76,0Z" />
    <path fill="currentColor" d="M76,19.38A56.63,56.63,0,1,1,19.38,76,56.68,56.68,0,0,1,76,19.38m0-2A58.63,58.63,0,1,0,134.62,76,58.61,58.61,0,0,0,76,17.38Z" />
    <path fill="currentColor" d="M100,76A24,24,0,1,1,76,52,24,24,0,0,1,100,76Z" />
    <path fill="currentColor" d="M76,37.55A38.45,38.45,0,1,1,37.55,76,38.5,38.5,0,0,1,76,37.55m0-2A40.45,40.45,0,1,0,116.45,76,40.45,40.45,0,0,0,76,35.55Z" />
  </SvgGraphic>
)

export const ObservatoryLogo: SvgGraphic = props => (
  <SvgGraphic viewBox="0 0 1080 1080" {...props}>
    <title></title>
    <line x1="548.39" y1="540" x2="859.34" y2="540" fill="none" stroke="currentColor" strokeWidth="9.984" strokeMiterlimit="10" />
    <line x1="47.89" y1="540" x2="226.06" y2="540" fill="none" stroke="currentColor" strokeWidth="9.984" strokeMiterlimit="10" />
    <line x1="540" y1="47.89" x2="540" y2="144.98" fill="none" stroke="currentColor" strokeWidth="9.984" strokeMiterlimit="10" />
    <line x1="540" y1="824.45" x2="540" y2="933.11" fill="none" stroke="currentColor" strokeWidth="9.984" strokeMiterlimit="10" />

    <circle cx="540" cy="540" r="492.11" overflow="visible" fill="none" stroke="currentColor" strokeWidth="9.984" strokeMiterlimit="10" />

    <circle cx="540" cy="540" r="492.11" fill="none" stroke="currentColor" strokeWidth="9.984" strokeMiterlimit="10" />
    <ellipse cx="540" cy="540" rx="319.51" ry="492.11" fill="none" stroke="currentColor" strokeWidth="9.984" strokeMiterlimit="10" />
    <ellipse cx="540" cy="540" rx="492.11" ry="285.47" fill="none" stroke="currentColor" strokeWidth="9.984" strokeMiterlimit="10" />
    <circle cx="540" cy="540" r="158.4" fill="currentColor" />
  </SvgGraphic>
)

const logoMap: Record<string, SvgGraphic | undefined> = {
  "movement-actions": MovementLogo,
  "movement-campaigns": MovementLogo,
  "movement-articles": MovementLogo,
  "wire-articles": WireLogo,
  "observatory-articles": ObservatoryLogo,
  "blueprint-documents": BlueprintLogo,
  movement: MovementLogo,
  wire: WireLogo,
  blueprint: BlueprintLogo,
  observatory: ObservatoryLogo,
}

export const PillarLogo: SvgGraphic<{ type: string }> = ({
  type,
  ...props
}) => {
  const Logo = logoMap[type]
  return Logo ? <Logo {...props} /> : null
}
