/** @jsx jsx */

import { jsx, SxStyleProp } from "theme-ui"
import { ErrorMessage, NestDataObject, FieldError } from "react-hook-form"
import {
  Fragment,
  DetailedHTMLProps,
  InputHTMLAttributes,
  SelectHTMLAttributes,
  TextareaHTMLAttributes,
} from "react"
import * as yup from "yup"
import { Label, Textarea, Input, Select } from "theme-ui"
import { Trans, useTranslation } from "react-i18next"

type YupObjectSchema = yup.ObjectSchema<{ [key: string]: any }>

const RequiredLabel = () => <span sx={{ color: "red" }}>&nbsp;*</span>

export function CheckboxInputArray<S extends YupObjectSchema>({
  children,
  name,
  schema,
  errors,
  register,
  labels,
  inverted = false,
  ...componentProps
}: {
  name: keyof S["fields"]
  errors: NestDataObject<any, FieldError>
  schema: S
  register: any
  labels: string[]
  inverted?: boolean
} & DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>) {
  const field = schema.fields[name]
  const hasErrors = errors[name]
  const errorMessage = (
    <Trans>
      <ErrorMessage errors={errors} name={name} />
    </Trans>
  )

  return (
    <Fragment>
      {errors && hasErrors && (
        <div
          tabIndex={0}
          sx={{ variant: inverted ? "forms.errorInverted" : "forms.error" }}
        >
          {errorMessage}
        </div>
      )}
      {labels.map((l, index) => {
        return (
          <Label sx={{ variant: "forms.label.checkboxOption" }}>
            <input
              sx={{ variant: inverted ? "forms.checkboxInverted" : "forms.checkbox" }}
              type="checkbox"
              name={name + `[${index.toString()}]`}
              title={l}
              ref={register}
            />
            {l}
            {/*field?._exclusive?.required && <RequiredLabel />*/}
          </Label>
        )
      })}
    </Fragment>
  )
}

export function CheckboxInput<S extends YupObjectSchema>({
  children,
  name,
  label,
  schema,
  errors,
  register,
  watch,
  ...props
}: {
  label?: string
  name: keyof S["fields"]
  errors: NestDataObject<any, FieldError>
  schema: S
  register: any
  watch: any
} & DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>) {
  const field = schema.fields[name]
  const _label = label || field?._label
  const hasErrors = errors[name]
  const errorMessage = (
    <Trans>
      <ErrorMessage errors={errors} name={name} />
    </Trans>
  )

  return (
    <Fragment>
      {errors && hasErrors && (
        <div tabIndex={0} sx={{ variant: "forms.error" }}>
          {errorMessage}
        </div>
      )}
      <Label sx={{ variant: "forms.label.checkboxOption", marginTop: "0px" }}>
        <input
          sx={{ variant: "forms.checkbox" }}
          type="checkbox"
          name={name}
          {...props}
          ref={register}
        />
        <Trans>{children || _label}</Trans>
        {field?._exclusive?.required && <RequiredLabel />}
      </Label>
    </Fragment>
  )
}

export function TextInput<S extends YupObjectSchema>({
  name,
  label,
  schema,
  errors,
  register,
  showLabel = true,
  sxLabel = {},
  inverted = false,
  showErrors = true,
  ...componentProps
}: {
  label?: string
  showLabel: boolean
  name: keyof S["fields"]
  errors: NestDataObject<any, FieldError>
  schema: S
  register: any
  sxLabel: SxStyleProp
  inverted?: boolean
  showErrors: boolean
} & DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>) {
  try {
    const id = `field-${name}`
    const field = schema.fields[name]
    const _label = label || field?._label
    const hasErrors = !!errors[name]
    const errorMessage = (
      <Trans>
        <ErrorMessage errors={errors} name={name} />
      </Trans>
    )

    return (
      <Fragment>
        {showLabel && _label && (
          <Label sx={{ ...sxLabel }} htmlFor={id}>
            <Trans>{_label}</Trans>
            {field?._exclusive?.required && <RequiredLabel />}
          </Label>
        )}
        {showErrors && errors && hasErrors && (
          <div
            tabIndex={0}
            sx={{ variant: inverted ? "forms.errorInverted" : "forms.error" }}
          >
            {errorMessage}
          </div>
        )}
        <Input
          type="text"
          {...componentProps}
          name={name}
          id={id}
          ref={register}
        />
      </Fragment>
    )
  } catch (e) {
    console.error(name, schema.fields)
    throw new Error(`${name} field probably does not exist in the Yup schema.`)
  }
}

export function TextAreaInput<S extends YupObjectSchema>({
  name,
  label,
  schema,
  errors,
  register,
  ...componentProps
}: {
  label?: string
  name: keyof S["fields"]
  errors: NestDataObject<any, FieldError>
  schema: S
  register: any
} & DetailedHTMLProps<
  TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
>) {
  try {
    const id = `field-${name}`
    const field = schema.fields[name]
    const _label = label || field?._label
    const hasErrors = errors[name]
    const errorMessage = (
      <Trans>
        <ErrorMessage errors={errors} name={name} />
      </Trans>
    )

    return (
      <Fragment>
        {_label && (
          <Label htmlFor={id}>
            <Trans>{_label}</Trans>
            {field?._exclusive?.required && <RequiredLabel />}
          </Label>
        )}
        {errors && hasErrors && (
          <div tabIndex={0} sx={{ variant: "forms.error" }}>
            {errorMessage}
          </div>
        )}
        <Textarea {...componentProps} name={name} id={id} ref={register} />
      </Fragment>
    )
  } catch (e) {
    console.error(name, schema.fields)
    throw new Error(`${name} field probably does not exist in the Yup schema.`)
  }
}

export function SelectInput<O, S extends YupObjectSchema>({
  name,
  label,
  options,
  errors,
  renderOption,
  schema,
  register,
  sxLabel = {},
  sxInput = {},
  inverted = false,
  ...inputProps
}: {
  label?: string
  name: keyof S["fields"]
  errors: NestDataObject<any, FieldError>
  options: O[]
  renderOption: (i: O, ind: number, arr: O[]) => any
  schema: S
  register: any
  sxLabel: SxStyleProp
  sxInput: SxStyleProp
  inverted?: boolean
} & DetailedHTMLProps<
  SelectHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement
>) {
  const { t } = useTranslation()
  try {
    const id = `field-${name}`
    const field = schema.fields[name]
    const _label = label || field?._label
    const hasErrors = errors[name]
    const errorMessage = (
      <Trans>
        <ErrorMessage errors={errors} name={name} />
      </Trans>
    )

    return (
      <Fragment>
        {_label && (
          <Label sx={{ ...sxLabel }} htmlFor={id}>
            <Trans>{_label}</Trans>
            {field?._exclusive?.required && <RequiredLabel />}
          </Label>
        )}
        {errors && hasErrors && (
          <div
            tabIndex={0}
            sx={{ variant: inverted ? "forms.errorInverted" : "forms.error" }}
          >
            {errorMessage}
          </div>
        )}
        <Select
          {...inputProps}
          sx={{ ...sxInput }}
          name={name}
          id={id}
          ref={register}
        >
          <option selected value={undefined} disabled>
            {t(`Pick an option`)}
          </option>
          {options.map(renderOption)}
        </Select>
      </Fragment>
    )
  } catch (e) {
    console.error(name, schema.fields)
    throw new Error(`${name} field probably does not exist in the Yup schema.`)
  }
}

export const donateItemContainer = {
  display: "grid",
  gridTemplateColumns: "1fr 1fr 1fr 1fr",
  gridColumnGap: "0.5em",
  gridRowGap: "0.5em",
}

export const donateButtonSx = {
  textAlign: "center",
  display: "inline-block",
  fontSize: [4, 4],
  fontWeight: 500,
  padding: 3,
  backgroundColor: "white",
  borderWidth: 2,
  borderStyle: "solid",
  borderRadius: 8,
  borderColor: "red",
  "input:checked + &": {
    backgroundColor: "red",
    color: "white",
  },
  ":hover, input:disabled + &:hover": {
    backgroundColor: "red",
    color: "white",
  },
  ":active": {
    backgroundColor: "darkRed !important",
    borderColor: "darkRed",
  },
  cursor: "pointer",
}

export const donateZeroButtonSx = {
  color: "rgba(0, 0, 0, 0.6)",
  fontFamily: "monospace",
  textAlign: "center",
  display: "inline-block",
  fontSize: [4, 4],
  fontWeight: 500,
  padding: 3,
  backgroundColor: "transparent",
  borderRadius: 8,
  ":hover": {
    color: "red",
    textDecoration: "underline",
  },
  "input:checked + &": {
    color: "red",
    textDecoration: "underline",
  },
  ":active": {
    color: "darkRed !important",
    textDecoration: "underline",
  },
  cursor: "pointer",
}

export const cryptoDetailsContainer = {
  marginTop: "5px",
  marginBottom: "15px",
  fontSize: "1.1em",
  lineHeight: "1.3em",
  textAlign: "left",
  ".cryptoDetails": {
    margin: "10px",
    marginTop: "15px",
    marginBottom: "15px",
    textAlign: "left",
    lineBreak: "anywhere",
  },
} as const

export function PillarFiltersStyling(
  pillar: string,
  fadedColour: string,
  unintrusiveColour: string,
  dir: any
) {
  const filterStyling = {
    maxHeight: ["49px", "none", "none"],
    overflowY: "hidden",
    flexWrap: "wrap",
    marginTop: "5px",
    fontWeight: "normal",
    fontSize: ["13px !important", "12px !important", "15px !important"],
    fontFamily: "'IBM Plex Mono', Menlo, monospace",
    label: {
      color: unintrusiveColour,
      position: "relative",
      fontWeight: "400",
      left: 0,
      top: 0,
      fontSize: "inherit !important",
      marginTop: "10px",
      marginBottom: "0px",
      lineHeight: "37px",
      borderTop: ["1px solid", "0px solid", "0px solid"],
      borderTopColor: pillar + "Hex",
      borderBottom: ["1px solid", "0px solid", "0px solid"],
      borderBottomColor: pillar + "Hex",
      div: {
        display: ["unset", "none", "none"],
        position: "absolute",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
        cursor: "pointer",
        select: {
          position: "absolute",
          width: "100%",
          height: "100%",
          border: "0px solid",
          WebkitTransform: "translate3d(0,0,0)",
          zIndex: "-1",
          "+ svg": {
            position: "absolute",
            top: "6px",
            right: dir.isRtl ? "initial" : 0,
            left: dir.isRtl ? 28 : "initial",
          },
        },
      },
    },
    "&.openFilter": {
      maxHeight: ["none", "none", "none"],
      label: {
        borderBottom: ["1px solid", "0px solid", "0px solid"],
        borderBottomColor: fadedColour,
        color: ["#111", unintrusiveColour, unintrusiveColour],
        div: {
          select: {
            "+ svg": {
              transform: "rotate(180deg)",
              top: "8px",
            },
          },
        },
      },
    },
  } as const

  return filterStyling
}

export function FilterStyling(
  filterSelectedId: string,
  pillar: string,
  fadedColour: string,
  unintrusiveColour: string,
  dir: any,
  isBottomFilter: boolean
) {
  let themeColor = "#280000"

  if (pillar != "") {
    themeColor = pillar + "Hex"
  }

  if (isBottomFilter) {
    const filterStyling = {
      color: !filterSelectedId ? unintrusiveColour : "#111",
      fontWeight: !filterSelectedId ? 400 : 700,
      fontFamily: "'IBM Plex Mono', Menlo, monospace",
      lineHeight: "22px",
      paddingLeft: "0px !important",
      outline: "none",
      border: 0,
      borderRadius: "0px",
      borderStyle: "solid",
      borderBottomWidth: !filterSelectedId
        ? ["1px", "1px", "1px"]
        : ["1px", "2px", "2px"],
      borderBottomColor: themeColor,
      marginBottom: "0px",
      "+ svg": {
        color: !filterSelectedId ? unintrusiveColour : "#111",
        marginLeft: dir ? (dir.isRtl ? 0 : -24) : 0,
        marginRight: dir ? (dir.isRtl ? -24 : 0) : 0,
      },
    } as const

    return filterStyling
  } else {
    const filterStyling = {
      color: !filterSelectedId ? unintrusiveColour : "#111",
      fontWeight: !filterSelectedId ? 400 : 700,
      fontFamily: "'IBM Plex Mono', Menlo, monospace",
      lineHeight: "22px",
      paddingLeft: "0px !important",
      outline: "none",
      border: 0,
      borderRadius: "0px",
      borderStyle: "solid",
      borderBottomWidth: !filterSelectedId ? "1px" : ["1px", "2px", "2px"],
      borderBottomColor: [fadedColour, themeColor, themeColor],
      marginBottom: "0px",
      "+ svg": {
        color: !filterSelectedId ? unintrusiveColour : "#111",
        marginLeft: dir ? (dir.isRtl ? 0 : -24) : 0,
        marginRight: dir ? (dir.isRtl ? -24 : 0) : 0,
      },
    } as const

    return filterStyling
  }
}
