/** @jsx jsx */
import { Fragment } from "react"
import { jsx, useThemeUI, Box, Heading, Button } from "theme-ui"
import { PageRoot } from "../data/content"
import { ContentBlock, HeaderSpacing } from "../components/page"
import { useTranslation, Trans } from "react-i18next"
import { IntroText } from "../components/intro-text"
import SEO from "../components/seo"
import { EmeritusGrid, PeopleGrid, useCouncilStaff } from "../components/people"
import { useTextDirection } from "../i18n"
import { Link } from "gatsby"
import { IndexPageContainer } from "../components/indexes"

const OrganisationIndex: PageRoot<any> = ({ location: { pathname } }) => {
  const { theme } = useThemeUI()
  const {
    i18n: { language },
    t,
  } = useTranslation()
  const dir = useTextDirection(language)

  const { emeritusRes } = useCouncilStaff()

  return (
    <IndexPageContainer
      title={t("Council")}
      color={"#FC164B"}
      bg={"#FED0DB"}
      heading={
        <Fragment>
          <p>
            <Trans>
              The Council of advisors is responsible for setting the strategic
              direction of the Progressive International.
            </Trans>
          </p>
          <p>
            <Trans>Read more about the members of the Council below.</Trans>
          </p>
        </Fragment>
      }
    >
      <ContentBlock>
        <SEO path={pathname} title={t("Council")} />

        <Box
          sx={{
            borderTop: ["2px solid " + theme.colors?.accent],
            mt: [1, 2, 3],
            mb: 4,
          }}
        />
        <PeopleGrid />
      </ContentBlock>
    </IndexPageContainer>
  )
}

export default OrganisationIndex
