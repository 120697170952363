/** @jsx jsx */

import { useState } from "react"
import { useForm } from "react-hook-form"
import { Rows, ViewElement } from "./atoms"
import * as yup from "yup"
import {
  candleJarContactFields,
  emailFields,
  interestTagFields,
  langFields,
  localeFields,
  personalFields,
} from "./joinform/join-data"
import { useAnalytics } from "../data/analytics"
import { graphql, navigate, useStaticQuery } from "gatsby"
import { jsx, Button, Input, Grid, Label, Flex, Box, Spinner } from "theme-ui"
import { Trans } from "react-i18next"
import {
  CheckboxInput,
  CheckboxInputArray,
  SelectInput,
  TextInput,
} from "./joinform/elements"
import languages from "../data/languages"
import { ContentNode } from "../data/content"
import { useLocalisedContentSimple } from "./featured"
import { FieldSetLegend } from "./semantics"
import useInterestTags from "../hooks/useInterestTags"

export const ActionNetworkFooterForm: ViewElement<{ popup?: boolean }> = ({
  popup = false,
}) => {
  const [requestError, setRequestError] = useState<String>()
  const [step, setStep] = useState(0)
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState<String>()

  const nextStep = () => {
    if (step >= 2) {
      setStep(0)
    } else {
      setStep(step + 1)
    }
  }

  const analytics = useAnalytics()

  if (loading) {
    return (
      <Box sx={{ margin: "auto" }}>
        <Spinner />
      </Box>
    )
  } else {
    switch (step) {
      case 0:
        return (
          <EmailStepForm
            setLoading={setLoading}
            setEmail={setEmail}
            setRequestError={setRequestError}
            requestError={requestError}
            nextStep={nextStep}
            analytics={analytics}
            popup={popup}
          />
        )
      case 1:
        if (email) {
          return (
            <InfoStepForm
              setLoading={setLoading}
              setRequestError={setRequestError}
              email={email}
              requestError={requestError}
              nextStep={nextStep}
              analytics={analytics}
            />
          )
        } else {
          return <div></div>
        }
      case 2:
        if (email) {
          return (
            <InterestsStepForm
              setLoading={setLoading}
              setRequestError={setRequestError}
              email={email}
              requestError={requestError}
              nextStep={nextStep}
              analytics={analytics}
            />
          )
        } else {
          return <div></div>
        }
      default:
        return <div></div>
    }
  }
}

const EmailStepForm = ({
  setLoading,
  setEmail,
  setRequestError,
  requestError,
  nextStep,
  analytics,
  popup,
}) => {
  const emailStepFields = {
    ...emailFields,
    ...candleJarContactFields,
  }

  const schema = yup.object({ ...emailStepFields })

  const { register, handleSubmit, watch, errors, setValue } = useForm({
    validationSchema: schema,
  })

  const inputProps = { register, schema, errors, watch }

  const onSubmit = async (data: yup.InferType<typeof schema>) => {
    setLoading(true)
    const { EMAIL, candle_jar_contact } = data
    const asBriefing = true

    const response = await fetch(`/.netlify/functions/signupUser`, {
      method: "POST",
      body: JSON.stringify({
        merge_fields: {
          EMAIL,
          candle_jar_contact,
        },
        asBriefing,
      }),
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
    })

    // check response; show general error if not ok
    if (!response.ok) {
      try {
        const json = await response.json()
        setRequestError(json.errorMessage)
      } catch (err) {
        setRequestError(
          "There was an error processing your request. Please, try again."
        )
      }
      setLoading(false)
    } else {
      // upon correct response, empty errors, empty input value, track event
      // and navigate to "subscriber" page
      setEmail(EMAIL)
      setRequestError("")
      setValue("EMAIL", "")
      analytics.trackEvent({
        category: "engagement",
        action: "subscribe",
      })
      setLoading(false)
      nextStep()
      // navigate(`/subscriber?EMAIL=${data.EMAIL}`)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid
        sx={{ fontSize: [18, null, 24], maxWidth: popup ? "60%" : "100%" }}
        columns={[1, null, popup ? 1 : 2]}
        gap={4}
      >
        <Box>
          <TextInput
            name="EMAIL"
            type="email"
            title="Email"
            autoComplete="email"
            inverted={true}
            sxLabel={{ marginTop: "0px" }}
            {...inputProps}
          />
        </Box>
        <Flex
          sx={{
            flexDirection: "column",
            justifyContent: "flex-end",
          }}
        >
          <Button
            variant="outlined"
            type="submit"
            value="subscribe"
            name="SUBSCRIBE"
          >
            <Trans>Subscribe</Trans>
          </Button>
        </Flex>
        {/*(errors.EMAIL || requestError) && (
          <div
            sx={{ gridColumn: "span 2", fontSize: [2, 3, 4], color: "white" }}
          >
            {(errors.EMAIL && "Please, enter a valid e-mail address.") ||
              requestError}
          </div>
        )*/}
      </Grid>

      {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups */}
      <div
        sx={{ position: "absolute", left: -5000, display: "none !important" }}
        aria-hidden="true"
      >
        <input
          type="text"
          name="candle_jar_contact"
          tabIndex={-1}
          autoComplete="off"
          defaultValue=""
          ref={register()}
        />
      </div>
    </form>
  )
}

const InfoStepForm = ({
  setLoading,
  setRequestError,
  email,
  requestError,
  nextStep,
  analytics,
}) => {
  const filteredLanguages = languages.filter(l =>
    ["en", "es", "de", "fr", "pt-br"].includes(l.isoName)
  )

  const infoStepFields = {
    ...personalFields,
    ...langFields(filteredLanguages.map(fl => fl.isoName)),
    ...candleJarContactFields,
  }

  const schema = yup.object({ ...infoStepFields })

  const { register, handleSubmit, watch, errors, setValue } = useForm({
    validationSchema: schema,
  })

  const inputProps = { register, schema, errors, watch }

  const onSubmit = async (data: yup.InferType<typeof schema>) => {
    setLoading(true)
    const { LANGUAGE, FNAME, LNAME, candle_jar_contact } = data
    const asBriefing = true

    const EMAIL = email

    const response = await fetch(`/.netlify/functions/signupUser`, {
      method: "POST",
      body: JSON.stringify({
        merge_fields: {
          EMAIL,
          LANGUAGE,
          FNAME,
          LNAME,
          candle_jar_contact,
        },
        asBriefing,
      }),
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
    })

    // check response; show general error if not ok
    if (!response.ok) {
      try {
        const json = await response.json()
        setRequestError(json.errorMessage)
      } catch (err) {
        setRequestError(
          "There was an error processing your request. Please, try again."
        )
      }
      setLoading(false)
    } else {
      // upon correct response, empty errors, empty input value, track event
      // and navigate to "subscriber" page
      setRequestError("")
      //analytics.trackEvent({
      //  category: "engagement",
      //  action: "subscribe",
      //})
      setLoading(false)
      nextStep()
      // navigate(`/subscriber?EMAIL=${data.EMAIL}`)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid sx={{ fontSize: [18, null, 24] }} columns={[1, 1, 1]} gap={2}>
        <Box>
          <SelectInput
            name="LANGUAGE"
            title="Language"
            sxLabel={{ marginTop: "0px" }}
            sxInput={{ backgroundColor: "#F7F4F1", border: "none" }}
            inverted={true}
            {...inputProps}
            options={filteredLanguages}
            renderOption={l => (
              <option key={l.isoName} value={l.isoName}>
                {l.name} / {l.nativeName}
              </option>
            )}
          />

          <TextInput
            title="First name"
            name="FNAME"
            autoComplete="given-name"
            inverted={true}
            {...inputProps}
          />

          <TextInput
            title="Last name"
            name="LNAME"
            autoComplete="family-name"
            inverted={true}
            {...inputProps}
          />
        </Box>
        <Flex
          sx={{
            flexDirection: "column",
            justifyContent: "flex-end",
            marginTop: "1em",
          }}
        >
          <Button
            variant="outlined"
            type="submit"
            value="subscribe"
            name="SUBSCRIBE"
          >
            <Trans>Continue</Trans>
          </Button>
        </Flex>
        {requestError && (
          <div
            sx={{ gridColumn: "span 2", fontSize: [2, 3, 4], color: "white" }}
          >
            {requestError}
          </div>
        )}
      </Grid>

      {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups */}
      <div
        sx={{ position: "absolute", left: -5000, display: "none !important" }}
        aria-hidden="true"
      >
        <input
          type="text"
          name="candle_jar_contact"
          tabIndex={-1}
          autoComplete="off"
          defaultValue=""
          ref={register()}
        />
      </div>
    </form>
  )
}

const InterestsStepForm = ({
  setLoading,
  setRequestError,
  email,
  requestError,
  nextStep,
  analytics,
}) => {
  const interestTags = useInterestTags()

  const interestsStepFields = {
    ...interestTagFields,
    ...candleJarContactFields,
  }

  const schema = yup.object({ ...interestsStepFields })

  const { register, handleSubmit, watch, errors, setValue } = useForm({
    validationSchema: schema,
  })

  const inputProps = { register, schema, errors, watch }

  const onSubmit = async (data: yup.InferType<typeof schema>) => {
    setLoading(true)
    const { INTEREST_TAGS, candle_jar_contact } = data
    const asBriefing = true

    const EMAIL = email

    const INTEREST_TAG_STRINGS = INTEREST_TAGS.map((t, index) => {
      if (t) {
        return interestTags[index].frontmatter.title
      }
    }).filter(t => t)

    const response = await fetch(`/.netlify/functions/signupUser`, {
      method: "POST",
      body: JSON.stringify({
        merge_fields: {
          EMAIL,
          INTEREST_TAG_STRINGS,
          candle_jar_contact,
        },
        asBriefing,
      }),
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
    })

    // check response; show general error if not ok
    if (!response.ok) {
      try {
        const json = await response.json()
        setRequestError(json.errorMessage)
      } catch (err) {
        setRequestError(
          "There was an error processing your request. Please, try again."
        )
      }
      setLoading(false)
    } else {
      // upon correct response, empty errors, empty input value, track event
      // and navigate to "subscriber" page
      setRequestError("")
      //analytics.trackEvent({
      //  category: "engagement",
      //  action: "subscribe",
      //})
      setLoading(false)
      nextStep()
      navigate(`/subscriber?EMAIL=${email}`)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid sx={{ fontSize: [18, null, 24] }} columns={[1, 1, 1]} gap={2}>
        <Box sx={{ fontSize: "0.75em" }}>
          <Label sx={{ marginTop: "0", marginBottom: "0.5em" }}>
            <Trans>What interests you most?</Trans>
          </Label>
          <FieldSetLegend legend="What interests you most?">
            <CheckboxInputArray
              labels={interestTags.map(t => useLocalisedContentSimple(t).title)}
              name={"INTEREST_TAGS"}
              inverted={true}
              {...inputProps}
            />
          </FieldSetLegend>
        </Box>
        <Flex
          sx={{
            flexDirection: "column",
            justifyContent: "flex-end",
          }}
        >
          <Button
            variant="outlined"
            type="submit"
            value="subscribe"
            name="SUBSCRIBE"
          >
            <Trans>Finish</Trans>
          </Button>
        </Flex>
        {(errors.LANGUAGE || errors.FNAME || errors.LNAME || requestError) && (
          <div
            sx={{ gridColumn: "span 2", fontSize: [2, 3, 4], color: "white" }}
          >
            {((errors.LANGUAGE || errors.FNAME || errors.LNAME) &&
              "There was an error.") ||
              requestError}
          </div>
        )}
      </Grid>

      {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups */}
      <div
        sx={{ position: "absolute", left: -5000, display: "none !important" }}
        aria-hidden="true"
      >
        <input
          type="text"
          name="candle_jar_contact"
          tabIndex={-1}
          autoComplete="off"
          defaultValue=""
          ref={register()}
        />
      </div>
    </form>
  )
}