/** @jsx jsx */
import { jsx, Box, Heading, Spinner } from 'theme-ui';
import { HeaderSpacing } from "../components/page"
import { useTranslation, Trans } from 'react-i18next';
import SEO from '../components/seo';
import { PageRoot } from '../data/content';
import SplitPage from "../components/split-page";
import { Fragment, useEffect, useRef, useState } from "react";
import { Dot } from "../components/content-page";
import { useTextDirection } from '../i18n';
import Helmet from "react-helmet";
import { SupportAction } from '../components/actions';

const JoinPage: PageRoot<{}> = ({ location: { pathname } }) => {
  const { i18n: { language }, t } = useTranslation()
  const dir = useTextDirection(language)

  return (
    <Fragment>
      <SEO
        path={pathname}
        title={'Support Progressive International'}
      />
      <SplitPage
        aside={
          <Fragment>
            <HeaderSpacing />
            <Heading as="h1" variant='headings.1'>
              <Trans>Contribute</Trans>
            </Heading>
            <Heading variant='headings.3' sx={{ mt: 3 }}>
              <Trans>The time has come for progressives to form a common front.</Trans>
            </Heading>
            <Heading variant='headings.3' sx={{ mt: 3 }}>
              <Trans>You can help us build it.</Trans>
            </Heading>
            <Heading variant='headings.3' sx={{ mt: 3 }}>
              <Trans>Progressive International receives no public funds or government grants of any kind.</Trans>
            </Heading>
            <Heading variant='headings.3' sx={{ mt: 3 }}>
              <Trans>We depend exclusively on our members’ financial support.</Trans>
            </Heading>
            <Box sx={{ display: ['none', 'block'], mt: '4em', mb: '1em' }}>
              <Dot size={[75, 75, 100]} />
            </Box>
          </Fragment>
        }
        main={
          <SupportAction language={language} />
        }
        sxAside={{
          textAlign: dir.left
        }}
        sxMain={{
          textAlign: dir.left
        }}
      />
    </Fragment>
  )
}

export default JoinPage