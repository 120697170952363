/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import React from "react"
import { PageGlobal } from "./src/components/page"
import { I18nextProvider } from "react-i18next"
import i18n from "./src/i18n"
import { analytics, AnalyticsProvider } from "./src/data/analytics"
import { HoldingPage } from "./src/components/HoldingPage"
import "./global.css"
import { SignupPopup } from "./src/components/popups"

export const wrapRootElement = ({ element }) => {
  return (
    <AnalyticsProvider>
      <I18nextProvider i18n={i18n}>{element}</I18nextProvider>
    </AnalyticsProvider>
  )
}

export const wrapPageElement = ({ element, props: { path } }) => {
  if (process.env.GATSBY_SHIELD_CONTENT) {
    return (
      <PageGlobal path={path} chrome={false}>
        <HoldingPage />
      </PageGlobal>
    )
  } else if (path == "/translations/") {
    return element
  } else {
    return (
      <PageGlobal path={path}>
        <SignupPopup />
        <>{element}</>
      </PageGlobal>
    )
  }
}

export const onRouteUpdate = ({ location }) => {
  analytics.pageView()
}

// Remove hover on mobile devices
// https://stackoverflow.com/questions/23885255/how-to-remove-ignore-hover-css-style-on-touch-devices
if (typeof document !== "undefined" && !hasTouch()) {
  document.body.className += " hasHover"
}

function hasTouch() {
  if (typeof document === "undefined") return
  return (
    "ontouchstart" in document.documentElement ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0
  )
}
