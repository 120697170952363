/** @jsx jsx */

import { jsx, Heading } from 'theme-ui';
import { Button } from 'theme-ui';
import { Link } from "../components/nav"
import { ContentBlock, HeaderSpacing, MapImageGlobal } from "../components/page"
import { Fragment } from "react";
import { useTranslation, Trans } from 'react-i18next';
import SEO from '../components/seo';
import { PageRoot } from '../data/content';
import { useTextDirection } from '../i18n';
import SplitPage from '../components/split-page';
import { BulletPoint, QABox } from '../components/atoms';

const JoinPage: PageRoot<{}> = ({ location: { pathname } }) => {
  const { i18n: { language }, t } = useTranslation()
  const dir = useTextDirection(language)

  const bulletPoint = require('../images/bulletpoint.svg')
  const bgOverride = require('../images/background.png')

  return (
    <Fragment>
      <SEO
        path={pathname}
        title={'Become a member of Progressive International'}
      />
      <SplitPage
        aside={
          <Fragment>
            <HeaderSpacing />
            <Heading variant='headings.1'>
              <Trans>
                The time has come for progressive forces to form a planetary front.
              </Trans>
            </Heading>
          </Fragment>
        }
        main={
          <Fragment>
            <HeaderSpacing />
            <Heading as="h1" variant='headings.2'>
              <Trans>
                Get involved with the Progressive International today.
              </Trans>
            </Heading>
            <Link to={`/join/individual${typeof window === `undefined` ? '' : window.location.search}`}>
              <Button variant='filled' sx={{ width: ['100%', '60%', '40%'], maxWidth: '210px', marginBottom: '14px', marginTop: '14px' }}><Trans>Get Started</Trans></Button>
            </Link>
            <BulletPoint
              imgSrc={bulletPoint}
              content={"As an individual, you can join a volunteer team or participate in a global day of action."}
            />
            <BulletPoint
              imgSrc={bulletPoint}
              content={"As an organisation, you can shape the future of the Progressive International — planning campaigns, joining working groups, and publishing declarations."}
            />
            <BulletPoint
              imgSrc={bulletPoint}
              content={"Or you can join us as a supporter and receive updates from the frontlines of our struggles."}
            />
            <QABox question="Does your organization want to become a member of the Progressive International?">
              Please write to <a sx={{ color: "#fc164b" }} href="mailto:members@progressive.international">members@progressive.international</a> and a member of the Secretariat will get in touch with you.
            </QABox>
            <QABox question="Need more information about the Progressive International?">
              Please write to <a sx={{ color: "#fc164b" }} href="mailto:info@progressive.international">info@progressive.international</a> and a member of the Secretariat will get in touch with you.
            </QABox>
          </Fragment>
        }
        bgOverride={bgOverride}
      />
    </Fragment>
    // <Fragment>
    //   <MapImageGlobal />
    //   <SEO
    //     path={pathname}
    //     title={'Become a member of Progressive International'}
    //   />
    //   <HeaderSpacing />
    //   <ContentBlock>
    //     <div sx={{
    //       width: ['100%', '75%', '45%'],
    //       float: dir.isRtl ? 'right' : 'left'
    //     }}>
    //       <Heading variant='headings.1' sx={{ textAlign: dir.left }}><Trans>
    //         Become a member
    //       </Trans></Heading>
    //       <Heading variant='headings.3' sx={{ mt: 3, textAlign: dir.left }}><Trans>
    //         The time has come for progressives to form a common front.
    //       </Trans></Heading>
    //       <Heading variant='headings.3' sx={{ mt: 3, textAlign: dir.left }}><Trans>
    //         Become a Member of the Progressive International today.
    //       </Trans></Heading>
    //       <div sx={{
    //         my: 5,
    //         display: 'grid',
    //         gap: 3,
    //         gridTemplateColumns: ['1fr', '1fr 1fr', '1fr 1fr', '1fr 1fr']
    //       }}>
    //         <Link to={`/join/individual${typeof window === `undefined` ? '' : window.location.search}`}>
    //           <Button variant='primary' sx={{ width: '100%' }}><Trans>Individuals</Trans></Button>
    //         </Link>
    //         <Link to={`/join/organisation${typeof window === `undefined` ? '' : window.location.search}`}>
    //           <Button variant='primary' sx={{ width: '100%' }}><Trans>Organisations</Trans></Button>
    //         </Link>
    //       </div>
    //     </div>
    //     <div sx={{ clear: 'both' }} />
    //   </ContentBlock>
    // </Fragment >
  )
}

export default JoinPage