/** @jsx jsx */
import { jsx, Box } from "theme-ui"
import React, { Fragment, useEffect } from "react"
import { graphql } from "gatsby"
import {
  ContentPage,
  ContentPageHeader,
  CompactContentHeader,
  ContentMetadata,
  ContentMetadataItem,
  GenericArticle,
  TopicPills,
} from "../components/content-page"
import { PageRoot, ContentNode } from "../data/content"
import { BlueprintLogo } from "../images/pillars"
import { NumericDate } from "../data/date"
import { Join } from "../data/list"
import { useTranslation } from "react-i18next"
import {
  useLocalisedContentViewer,
  useLocalisedContent,
  useLocalisedContentArray,
} from "../components/featured"
import SEO from "../components/seo"
import { ArticleLanguageSelector } from "../components/content-page"
import { Link } from "../components/nav"
import { useAnalytics } from "../data/analytics"
import { useTextDirection, TextDirectionProvider } from "../i18n"
import { ActionData } from "../components/actions"
import { ShareData, SocialSharer } from "../components/share"
import { ruLinebreakRegex } from "../data/methods"

const BlueprintDocument: PageRoot<{
  node: ContentNode
  donationBanner: any
}> = ({
  data: { node, donationBanner },
  pageContext: { locale, locales },
  location,
  location: { pathname },
}) => {
  const { t } = useTranslation()
  useLocalisedContentViewer(locale, locales)
  const localisedContent = useLocalisedContent(node, locale)
  const localisedDonationBanner = useLocalisedContent(donationBanner, locale)
  const localisedTopic = useLocalisedContent(node.frontmatter.topic, locale)
  const localisedSecondaryTopics = useLocalisedContentArray(
    node.frontmatter.secondaryTopics,
    locale
  )
  const dir = useTextDirection(locale)
  const analytics = useAnalytics()

  let anData = undefined

  if (
    localisedContent.anLink &&
    localisedContent.anIdentifier &&
    localisedContent.anSize &&
    localisedContent.anType
  ) {
    anData = new ActionData(
      localisedContent.anLink,
      localisedContent.anIdentifier,
      localisedContent.anSize,
      localisedContent.anType
    )
  }

  let shareData = undefined

  if (location?.href && localisedContent.title) {
    const shareMessage = localisedContent.title
    shareData = new ShareData(shareMessage, location?.href, shareMessage)
  }

  useEffect(() => {
    if (locale == "ru") {
      ruLinebreakRegex()
    }
  }, [locale])

  return (
    <TextDirectionProvider locale={locale}>
      <Box sx={{ textAlign: dir.defaultDir }}>
        <SEO
          path={pathname}
          lang={localisedContent.locale}
          title={localisedContent.title}
          description={
            localisedContent.shortAbstract || localisedContent.abstract
          }
          imageUrl={node.frontmatter.featuredImage?.publicURL}
          locales={locales}
        />
        <ContentPageHeader
          backgroundImage={require("../images/dymaxion-dash.png")}
          node={node}
          alt={localisedContent.featuredImageAlt}
        >
          <CompactContentHeader
            className="article-content"
            {...localisedContent}
            content={node}
          />
        </ContentPageHeader>

        <ContentPage
          {...localisedContent}
          logo={<BlueprintLogo />}
          content={node}
          anData={anData ? anData : undefined}
          coloredHeadings
          toc={true}
          meta={
            <ContentMetadata color={node.fields.themeColor}>
              {locales?.length && (
                <ArticleLanguageSelector locale={locale} locales={locales} />
              )}
              {!!node.frontmatter.authors?.length && (
                <ContentMetadataItem label={t(`Authors`)}>
                  <Join
                    value={
                      node === null
                        ? " "
                        : node.frontmatter.authors.map(a =>
                            a === null ? " " : a.frontmatter.title
                          )
                    }
                  />
                </ContentMetadataItem>
              )}
              {!!localisedContent.translators?.length && (
                <ContentMetadataItem
                  label={
                    <span>
                      {localisedContent.translators?.length > 1
                        ? t(`Translators`)
                        : t(`Translator`)}
                    </span>
                  }
                >
                  <Join
                    value={localisedContent.translators?.map(a =>
                      a === null ? " " : a.frontmatter.title
                    )}
                  />
                </ContentMetadataItem>
              )}
              <ContentMetadataItem label={t(`Published`)}>
                <NumericDate
                  value={node === null ? " " : node.frontmatter.publishDate}
                />
              </ContentMetadataItem>

              {!!node.frontmatter.organisations && (
                <ContentMetadataItem label={t(`Partner organisation`)}>
                  <Join
                    value={
                      node === null
                        ? " "
                        : node.frontmatter.organisations?.map(a =>
                            a === null ? " " : a.frontmatter.title
                          )
                    }
                  />
                </ContentMetadataItem>
              )}

              {!!node.frontmatter.document && (
                <Link
                  to={
                    node === null ? " " : node.frontmatter.document.publicURL!
                  }
                  target="_blank"
                  linkType="download"
                >
                  <ContentMetadataItem label={t(`Download`) + " ↧"} />
                </Link>
              )}
              {shareData && (
                <Box id="social-sharer">
                  <SocialSharer
                    shareData={shareData}
                    type={node.frontmatter.pillar || node.fields.type}
                    sx={{ paddingTop: "15px", paddingBottom: "25px" }}
                  />
                </Box>
              )}
              {localisedSecondaryTopics && localisedSecondaryTopics.length > 0 && (
                <Box>
                  <TopicPills
                    mainTopic={localisedTopic}
                    topics={localisedSecondaryTopics}
                    type={node.frontmatter.pillar || node.fields.type}
                    sx={{ paddingTop: "15px", paddingBottom: "25px" }}
                  />
                </Box>
              )}
            </ContentMetadata>
          }
          children={
            <Box sx={{ my: 4 }}>
              <Fragment>
                <GenericArticle
                  {...localisedContent}
                  anData={anData ? anData : undefined}
                  pillar={
                    node.frontmatter.pillar
                      ? node.frontmatter.pillar
                      : node.fields.type
                      ? node.fields.type
                      : undefined
                  }
                  donationBanner={localisedDonationBanner.html}
                  themeColor={node.fields.themeColor}
                />
              </Fragment>
            </Box>
          }
        />
      </Box>
    </TextDirectionProvider>
  )
}

export const pageQuery = graphql`
  query BlueprintDocument($id: String!) {
    node: markdownRemark(id: { eq: $id }) {
      ...ContentPageFragment
    }

    # Donation Banner
    donationBanner: markdownRemark(
      frontmatter: { contentId: { eq: "blueprint-donation-banner" } }
    ) {
      fields {
        translations {
          childMarkdownRemark {
            html
            frontmatter {
              locale
            }
          }
        }
      }
    }
  }
`

export default BlueprintDocument
