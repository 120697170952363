import { graphql, useStaticQuery } from "gatsby"
import { ContentNode } from "../data/content"

const useInterestTags = () => {
  const res = useStaticQuery(INTEREST_TAG_QUERY)
  //console.log("res: ", res);
  const edges = res.allMarkdownRemark.edges.map(e => e.node) as ContentNode[]
  return edges.sort(compareInterestTags)
}

const compareInterestTags = (a, b) => {
  if (a.frontmatter.title < b.frontmatter.title) {
    return -1
  } else if (a.frontmatter.title > b.frontmatter.title) {
    return 1
  }
  return 0
}

const INTEREST_TAG_QUERY = graphql`
  query InterestTagsQuery {
    allMarkdownRemark(filter: { fields: { type: { eq: "interest-tags" } } }) {
      edges {
        node {
          frontmatter {
            title
          }
          fields {
            translations {
              childMarkdownRemark {
                frontmatter {
                  title
                  locale
                }
              }
            }
          }
        }
      }
    }
  }
`

export default useInterestTags
