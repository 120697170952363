/** @jsx jsx */

import { jsx, Heading, Container } from "theme-ui"
import { PageRoot, Connection, ContentNode } from "../data/content"
import { graphql, navigate } from "gatsby"
import { PillarLogo } from "../images/pillars"
import { Link } from "../components/nav"
import { ContentBlock, HeaderSpacing } from "../components/page"
import { useState, useEffect } from "react"
import { get } from "lodash"
import { useTranslation, Trans } from "react-i18next"
import { useLocalisedContent, Localised } from "../components/featured"
import theme from "../gatsby-plugin-theme-ui/index"
import SEO from "../components/seo"
import { useTextDirection } from "../i18n"

const OrganisationIndex: PageRoot<{
  organisations: Connection<ContentNode>
}> = ({ data: { organisations }, location: { pathname } }) => {
  // map node prop path by criteria
  const sortingPropByCriteria = {
    organisation: "title",
    region: "location",
    theme: "topic.fields.translations[0].childMarkdownRemark.frontmatter.title",
  }
  const [membersList, setMembersList] = useState(organisations.edges)
  const [sortingCriteria, setSortingCriteria] = useState("organisation")

  // `sortBy` method receives a sorting criteria to sort the members list
  const sortBy = criteria => {
    const prop = sortingPropByCriteria[criteria]
    const sortedList = [...membersList].sort((a, b) => {
      // set sorting as case insensitive, and default to "|" character for
      // undefined values; this makes sure that it will always go last
      const valueA = get(a.node.frontmatter, prop)?.toLowerCase() || "|"
      const valueB = get(b.node.frontmatter, prop)?.toLowerCase() || "|"
      if (valueA > valueB) return 1
      if (valueA < valueB) return -1
      return 0
    })
    setMembersList(sortedList)
    setSortingCriteria(criteria)
  }

  const { i18n: { language }, t } = useTranslation()
  const dir = useTextDirection(language)

  // initialize sorting
  useEffect(() => sortBy(sortingCriteria), [sortingCriteria])

  return (
    <ContentBlock>
      <SEO path={pathname} title={"Members"} />
      <HeaderSpacing />
      <Heading as="h1" variant="headings.1" sx={{ color: "accent", textAlign: dir.left }}>
        <Trans>Members</Trans>
      </Heading>

      <table
        sx={{
          display: ["block", null, "table"],
          width: "100%",
          direction: dir.isRtl ? "rtl" : "default",
          fontSize: [15, null, 20],
          lineHeight: "110%",
          letterSpacing: -0.4,
          borderSpacing: 0,
          mt: 5,
          mb: 6,

          "> *": {
            width: "100%",
          },

          th: {
            textAlign: dir.left
          },

          tr: {
            position: "relative",
            display: ["flex", null, "table-row"],
            flexDirection: "row",
            flexWrap: "wrap",
            borderBottom: ["2px solid " + theme.colors.grey, null, "none"],
          },

          "tbody tr:not(:first-child)": {
            pt: [2, null, 0],
          },

          "tbody tr": {
            pb: [2, null, 0],
          },

          "tr > *": {
            display: ["block", null, "table-cell"],
            py: [1, null, 3],
            borderBottom: [null, null, "2px solid " + theme.colors.grey],

            "&:not(:last-child)": {
              px: [2, null, 0],
            },
          },
        }}
      >
        <thead sx={{ display: ["none", null, "table-header-group"] }}>
          <tr
            sx={{
              "> *": {
                textAlign: "left",
                fontFamily: theme.fonts.monospace,
                fontSize: 15,
                lineHeight: "110%",
                fontWeight: 400,
                letterSpacing: -0.4,
                mx: 0,
                borderCollapse: "collapse",
              },
            }}
          >
            <th>
              <span
                sx={{
                  opacity: sortingCriteria === "organisation" ? 1 : 0.5,
                  cursor: "pointer",
                  ":hover": {
                    opacity: 1,
                  },
                }}
                onClick={() => sortBy("organisation")}
              >
                <Trans>Organisation</Trans>
              </span>
            </th>
            <th>
              <span
                sx={{
                  opacity: sortingCriteria === "region" ? 1 : 0.5,
                  cursor: "pointer",
                  ":hover": {
                    opacity: 1,
                  },
                }}
                onClick={() => sortBy("region")}
              >
                <Trans>Region</Trans>
              </span>
            </th>
            <th>
              <span
                sx={{
                  opacity: sortingCriteria === "theme" ? 1 : 0.5,
                  cursor: "pointer",
                  ":hover": {
                    opacity: 1,
                  },
                }}
                onClick={() => sortBy("theme")}
              >
                <Trans>Theme</Trans>
              </span>
            </th>
            <th />
          </tr>
        </thead>
        <tbody sx={{ display: ["block", null, "table-row-group"] }}>
          {membersList.map(({ node }) => (
            <DirectoryRow key={node.id} node={node} />
          ))}
        </tbody>
      </table>
    </ContentBlock>
  )
}

const DirectoryRow = ({ node }) => {
  const { title, path } = useLocalisedContent(node)
  return (
    <tr
      sx={{
        cursor: "pointer",
        ":hover": {
          color: node.fields.themeColor,
          " > *": {
            borderBottom: [null, null, `2px solid ${theme.colors.black}`],
            position: [null, null, "relative"],
            "::before": {
              content: [null, null, "''"],
              width: "100%",
              height: "2px",
              backgroundColor: theme.colors.black,
              position: "absolute",
              top: "-2px",
              left: "0",
            },
          },
        },
      }}
      onClick={() => navigate(path)}
    >
      <td sx={{ fontWeight: 600, width: ["100%", null, "initial"] }}>
        <Link to={path}>{title}</Link>
      </td>
      <td>{node.frontmatter.location}</td>
      <td>
        <Localised node={node.frontmatter.topic}>{n => n?.title}</Localised>
      </td>
      <td
        sx={{
          position: ["absolute", null, "initial"],
          right: 0,
          top: ["50%", null, "auto"],
          transform: ["translateY(-50%)", null, "none"],
        }}
      >
        <PillarLogo
          type={node.frontmatter.pillar}
          sx={{
            width: [36, null, 26],
            height: [36, null, 26],
            color: node.fields.themeColor,
          }}
        />
      </td>
    </tr>
  )
}

export const pageQuery = graphql`
  query OrganisationIndex {
    organisations: allMarkdownRemark(
      filter: {
        fields: { type: { eq: "partner-orgs" } }
        frontmatter: {
          title: { regex: "/^((?!Progressive International).)*$/" }
          topic: { frontmatter: { contentId: { ne: "854663c1-ee6a-4735-a211-945e7e435ed5" } } }
        }
      }
      sort: { fields: [frontmatter___title], order: ASC }
    ) {
      edges {
        node {
          ...ListFragment
        }
      }
    }
  }
`

export default OrganisationIndex
