/** @jsx jsx */

import { jsx, Heading, Box } from "theme-ui"
import React, { Fragment, useEffect } from "react"
import { graphql } from "gatsby"
import {
  ContentPage,
  ContentPageHeader,
  CompactContentHeader,
  TopicPills,
} from "../components/content-page"
import { PageRoot, ContentNode } from "../data/content"
import {
  FeaturedList,
  FeaturedListItem,
  FeaturedResource,
  useLocalisedContentViewer,
  useLocalisedContent,
  useLocalisedContentArray,
} from "../components/featured"
import { Link } from "../components/nav"
import { useTranslation, Trans } from "react-i18next"
import SEO from "../components/seo"
import {
  ContentMetadata,
  ContentMetadataItem,
  GenericArticle,
  ArticleLanguageSelector,
} from "../components/content-page"
import { Join } from "../data/list"
import { NumericDate } from "../data/date"
import { TextDirectionProvider, useTextDirection } from "../i18n"
import {
  ActionData,
  ActionSidebar,
  isActionSidebar,
} from "../components/actions"
import { ShareData, SocialSharer } from "../components/share"
import { ruLinebreakRegex } from "../data/methods"

const MovementCampaign: PageRoot<{
  node: ContentNode
  donationBanner: any
}> = ({
  data: { node, donationBanner },
  pageContext: { locale, locales },
  location,
  location: { pathname },
}) => {
  const { t } = useTranslation()
  useLocalisedContentViewer(locale, locales)
  const localisedContent = useLocalisedContent(node, locale)
  const localisedDonationBanner = useLocalisedContent(donationBanner, locale)
  const localisedTopic = useLocalisedContent(node.frontmatter.topic, locale)
  const localisedSecondaryTopics = useLocalisedContentArray(
    node.frontmatter.secondaryTopics,
    locale
  )
  const dir = useTextDirection(locale)

  let anData = undefined

  if (
    localisedContent.anLink &&
    localisedContent.anIdentifier &&
    localisedContent.anSize &&
    localisedContent.anType
  ) {
    anData = new ActionData(
      localisedContent.anLink,
      localisedContent.anIdentifier,
      localisedContent.anSize,
      localisedContent.anType
    )
  }

  let shareData = undefined

  if (location?.href && localisedContent.title) {
    const shareMessage = localisedContent.title
    shareData = new ShareData(shareMessage, location?.href, shareMessage)
  }

  useEffect(() => {
    if (locale == "ru") {
      ruLinebreakRegex()
    }
  }, [locale])

  return (
    <TextDirectionProvider locale={locale}>
      <Box sx={{ textAlign: dir.defaultDir }}>
        <SEO
          path={pathname}
          lang={localisedContent.locale}
          title={localisedContent.title}
          description={
            localisedContent.shortAbstract || localisedContent.abstract
          }
          imageUrl={node.frontmatter.featuredImage?.publicURL}
          locales={locales}
        />
        <ContentPageHeader node={node} alt={localisedContent.featuredImageAlt}>
          <CompactContentHeader
            className="article-content"
            {...localisedContent}
            content={node}
          />
        </ContentPageHeader>

        <ContentPage
          {...localisedContent}
          html={localisedContent.html}
          content={node}
          anData={anData ? anData : undefined}
          children={
            <Fragment>
              <GenericArticle
                {...localisedContent}
                anData={anData ? anData : undefined}
                pillar={
                  node.frontmatter.pillar
                    ? node.frontmatter.pillar
                    : node.fields.type
                    ? node.fields.type
                    : undefined
                }
                donationBanner={localisedDonationBanner.html}
                themeColor={node.fields.themeColor}
              />
              {node.frontmatter.associatedActions?.length > 0 && (
                <Fragment>
                  <Heading
                    variant="headings.3"
                    sx={{ mb: 3, color: node.fields.themeColor }}
                  >
                    <Trans>Take Action</Trans>
                  </Heading>

                  <FeaturedList sx={{ mb: 7 }}>
                    {node.frontmatter.associatedActions?.map(action => (
                      <Link key={node.id} to={action.fields.path}>
                        <FeaturedListItem key={node.id} node={action} />
                      </Link>
                    ))}
                    <div sx={{ borderTop: "2px solid black" }} />
                  </FeaturedList>
                </Fragment>
              )}
              {node.frontmatter.resources?.length > 0 && (
                <Fragment>
                  <Heading
                    variant="headings.3"
                    sx={{ mb: 3, color: node.fields.themeColor }}
                  >
                    <Trans>Resources</Trans>
                  </Heading>

                  <FeaturedList sx={{ mb: 7 }}>
                    {node.frontmatter.resources?.map(resource => (
                      <FeaturedResource
                        node={resource}
                        themeColor={node.fields.themeColor}
                      />
                    ))}
                    <div sx={{ borderTop: "2px solid black" }} />
                  </FeaturedList>
                </Fragment>
              )}
            </Fragment>
          }
          meta={
            <ContentMetadata color={node.fields.themeColor}>
              {locales?.length && (
                <ArticleLanguageSelector locale={locale} locales={locales} />
              )}
              {!!node.frontmatter.authors?.length && (
                <ContentMetadataItem label={t(`Authors`)}>
                  <Join
                    value={node.frontmatter.authors.map(
                      a => a.frontmatter.title
                    )}
                  />
                </ContentMetadataItem>
              )}
              {!!localisedContent.translators?.length && (
                <ContentMetadataItem
                  label={
                    <span>
                      {localisedContent.translators?.length > 1
                        ? t(`Translators`)
                        : t(`Translator`)}
                    </span>
                  }
                >
                  <Join
                    value={localisedContent.translators?.map(
                      a => a.frontmatter.title
                    )}
                  />
                </ContentMetadataItem>
              )}
              <ContentMetadataItem label={t("Date")}>
                <NumericDate value={node.frontmatter.startDate} />
              </ContentMetadataItem>
              {!!node.frontmatter.organisations && (
                <ContentMetadataItem label={t(`Organisers`)}>
                  <Join
                    value={node.frontmatter.organisations?.map(
                      a => a.frontmatter.title
                    )}
                  />
                </ContentMetadataItem>
              )}
              {shareData && (
                <Box id="social-sharer">
                  <SocialSharer
                    shareData={shareData}
                    type={node.frontmatter.pillar || node.fields.type}
                    sx={{ paddingTop: "15px", paddingBottom: "25px" }}
                  />
                </Box>
              )}
              {localisedSecondaryTopics && localisedSecondaryTopics.length > 0 && (
                <Box>
                  <TopicPills
                    mainTopic={localisedTopic}
                    topics={localisedSecondaryTopics}
                    type={node.frontmatter.pillar || node.fields.type}
                    sx={{ paddingTop: "15px", paddingBottom: "25px" }}
                  />
                </Box>
              )}
              {(node.frontmatter.pillar || node.fields.type) &&
                anData &&
                anData.isTruthy() &&
                isActionSidebar(anData.anType) && (
                  <Box>
                    <ActionSidebar
                      anData={anData}
                      type={
                        node.frontmatter.pillar
                          ? node.frontmatter.pillar
                          : node.fields.type
                      }
                    />
                  </Box>
                )}
            </ContentMetadata>
          }
        />
      </Box>
    </TextDirectionProvider>
  )
}

export const pageQuery = graphql`
  query MovementCampaign($id: String!) {
    node: markdownRemark(id: { eq: $id }) {
      ...ContentPageFragment
    }

    # Movement Banner
    donationBanner: markdownRemark(
      frontmatter: { contentId: { eq: "movement-donation-banner" } }
    ) {
      fields {
        translations {
          childMarkdownRemark {
            html
            frontmatter {
              locale
            }
          }
        }
      }
    }
  }
`

export default MovementCampaign
