/** @jsx jsx */
import { Fragment } from "react"
import { jsx, useThemeUI, Box, Heading } from 'theme-ui';
import { PageRoot } from "../data/content"
import { ContentBlock, HeaderSpacing } from "../components/page"
import { useTranslation, Trans } from 'react-i18next';
import { IntroText } from '../components/intro-text';
import SEO from '../components/seo';
import { CabinetGrid } from "../components/cabinet"
import { useTextDirection } from "../i18n";

const OrganisationIndex: PageRoot<any> = ({
  location: { pathname }
}) => {
  const { theme } = useThemeUI()
  const { i18n: { language }, t } = useTranslation()
  const dir = useTextDirection(language)

  return (
    <ContentBlock>
      <SEO
        path={pathname}
        title={t('Cabinet')}
      />
      <HeaderSpacing />
      <Heading as="h1" variant='headings.1' sx={{ textAlign: dir.left }}>
        <Trans>Cabinet</Trans>
      </Heading>
      <div sx={{ display: 'flex', justifyContent: "flex-start", textAlign: dir.left }}>
        <IntroText>
          <Fragment>
            <p><Trans>The Cabinet is the executive body of the Progressive International.</Trans></p>
            <p><Trans>It is responsible for development, planning, and staffing decisions.</Trans></p>
            <p><Trans>The body is comprised of eight members of the Council and two representatives from the Secretariat.</Trans></p>
          </Fragment>
        </IntroText>
      </div>
      <Box sx={{
        borderTop: [
          "2px solid " + theme.colors?.accent,
        ],
        mt: [4, 5, 6],
        mb: 4
      }} />
      <CabinetGrid />
    </ContentBlock>
  )
}



export default OrganisationIndex
